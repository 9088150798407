a.navbar-brand > img:first-of-type {
  width: 40px;
}

a.navbar-brand > img:nth-of-type(2) {
  height: 40px;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  left: 50%;
  transform: translateX(-50%);
}

.override_justify_right {
  margin-left: auto;
  margin-right: 1.5rem;
}

.in_aresult {
  float: right;
  width: 20px;
  height: 20px;
}

.content-header-title.float-start.mb-0.breadcrumb_less {
  border-right: none;
}

a.brand-logo img:first-of-type, a.brand-logo img:nth-of-type(2) {
  height: 40px;
}

table > tbody > tr > td > span.table_sf {
  font-size: 12px;
}

.table-hover tbody tr {
  cursor: default;
}

.tooltip_div {
  display: inline-block;
}

.btn-group.shop_select > .dropdown-menu > a:last-of-type {
  color: #fff;
  background: #7367f0;
}

.btn-group.shop_select > .dropdown-menu > a:last-of-type:hover {
  background-color: #887ef2;
}

li.list-group-item.title_button, .dd_add_new {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

li.list-group-item.title_button h4 {
  margin-bottom: -2px;
}

.no-p-b {
  padding-bottom: 0;
}

.mt-24 {
  margin-top: 24px !important;
}

.item-img.text-center {
  padding: 30px !important;
}

.navbar-brand img {
  width: 175px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #8aa795 !important;
  border-color: #8aa795 !important;
}

.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #8aa795;
}

.btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: #fff;
  background-color: #789a85 !important;
}

.ecommerce-application .list-view .ecommerce-card .item-options .item-wrapper .item-cost .item-price {
  color: #5d7b68;
  margin-bottom: 0;
}

.badge.rounded-pill.bg-primary.badge-up.cart-item-count {
  background: #8aa795 !important;
}

html body {
  direction: ltr;
  background-color: #f8f3eb;
  height: 100%;
}

.ecommerce-card .item-name.available {
  color: #16c653;
}

.ecommerce-card .item-name.notavailable {
  color: #fac401;
}

.item-name.skuvalue {
  flex: -moz-available;
  display: block;
  margin-top: 0 !important;
}

.item-name.inventory.detailpage {
  flex: -moz-available;
  display: block;
  margin-bottom: 0 !important;
}

.item-options.stock-notavailable > a {
  color: #cecece !important;
  background: #cecece !important;
  border-color: #cecece !important;
}

.item-options.stock-notavailable > a:after {
  content: "Varen er ikke på lager";
  width: 100%;
  position: absolute;
  left: 0;
  color: #fff !important;
}

.card.ecommerce-card.stock-notavailable {
  opacity: .6;
  pointer-events: none !important;
}

/*# sourceMappingURL=index.9cb8fe87.css.map */
